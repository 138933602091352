import axios from "axios";
import { ElMessage, ElMessageBox } from "element-plus";
import erwm from "@/assets/erwm.png";
import numd from "@/assets/ls/acdb15e293cc0d98896ca64e7f438f7.png";
import asdasddas from "@/assets/ls/1733132144982.png";

import "element-plus/es/components/message-box/style/css";
import router from "@/router";
import { onMounted, ref, nextTick, watch } from "vue";
const overlayClassName = "custom-transparent-overlay";
import "./asdasj.css";

// 国际
//  let api ="https://global-work.smartschool.chat"
//  let tools ="https://global-api.smartschool.chat"
//  let wws ="wss://global-work.smartschool.chat/ws/somepath/"
//  let spoken ="https://global-work.smartschool.chat"
//  let word ="https://global.smartschool.chat"
//  let python ="https://global-pa-api.smartschool.chat"

//  let api ="http://192.168.31.39:8000"
// let tools ="http://192.168.31.39:5002"
// let wws ="ws://192.168.31.39:8000/ws/somepath/1/"
// let word ="http://localhost:8000"
// let word ="http://192.168.31.138:8000"
// let python ="http:/192.168.31.39:8000"
// let spoken ="http://192.168.31.39:8000"

// let multiPersonSynthesis ="http://124.232.165.170:8000"


// let api ="http://111.229.28.68:8000"
// let tools ="http://111.229.28.68:5002"
// let wws ="ws://111.229.28.68:8000/ws/somepath/1/"
// let word ="http://localhost:8001"
// // let word ="http://192.168.31.138:8000"
// // let word ="http://111.229.28.68:8000"
// let python ="http:/192.168.31.138:8001"
// let spoken ="http://111.229.28.68:8000"

// 国内
let python = "https://pa-api.smartschool.chat";

let api = "https://work.smartschool.chat";
let tools = "https://api.smartschool.chat";
let wws = "wss://work.smartschool.chat/ws/somepath/";
let word = "https://app.smartschool.chat";
// 
let spoken = "https://work.smartschool.chat";









let multiPersonSynthesis ="https://ttsserv.smartschool.chat"


let spodasdken = "https://api-us.smartschool.chat/";

const request = axios.create({
  baseURL: api,
  timeout: 120000,
});
// 请求拦截器

let vipkoc = ref();

request.interceptors.request.use(
  (config) => {
    // config.headers['Cache-Control'] = 'no-cache';
    // 检查是否有需要发送的数据
    //  loading = ElLoading.service({
    //     lock: true,
    //     text: 'SmartSchool',
    //     background: 'rgba(0, 0, 0, 0.7)',
    //   })
    if (config.data) {
      // config.headers['Cache-Control'] = 'max-age=25920000';
      // 创建FormData对象
      const formData = new FormData();
      // 遍历对象属性，添加到FormData中
      for (const key in config.data) {
        if (config.data.hasOwnProperty(key)) {
          formData.append(key, config.data[key]);
        }
      }
      // 更新config.data为转换后的FormData
      config.data = formData;
      // 设置请求头为表单格式
      config.headers["Content-Type"] = "multipart/form-data";
    }

    let token = localStorage.getItem("cc");

    if (token && token != "undefined") {
      // 设置请求的头信息
      config.headers["Authorization"] = "Bearer " + token; // 请求添加的token（此处也可不写）
    }
    // 请求添加的token（此处也可不写）
    //  config.headers['Authorization'] ="Bearer "+"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzExNDE2NzYxLCJpYXQiOjE3MTEzMzAzNjEsImp0aSI6ImIxMzAyYmFmNzUxNjQyZDM5YzNiZjBhYzRkOTgwOWM3IiwidXNlcl9pZCI6MjN9.FrRthNIUEXSarMMhdp78Fgh4VVeVKsy4_W9p8ci6-AE.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzExMTcxODEyLCJpYXQiOjE3MTEwODU0MTIsImp0aSI6IjA3NGVlZThkNGE3ODQ3ZGM4NWIwNmIwNWJjOWQ2ZDJmIiwidXNlcl9pZCI6MjN9.MF44XCoi-eznFAPBy2G7fzm98uUnRJ0fHqmEXeryaZQ"; // 请求添加的token（此处也可不写）

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
); // 添加响应拦截器
request.interceptors.response.use(
  (response) => {
    // 对响应数据做些什么

    if (
      response.data.status != "200" &&
      response.data.status != "401" &&
      response.data.status != "51314" &&
      response.data.status != "41309" &&
      response.data.status != "51316" &&
      response.data.status != "51315" &&
      response.data.status != "51317" &&
      response.data.status != "403" &&
      response.data.status != "4321512" &&
      response.data.status != "415135" &&
      response.data.status != "252352" &&
      response.data.status != "9527" &&
      response.data.status != "9528"
    ) {
      ElMessage({
        message: response.data.message,
        type: "error",
      });
    }
    if (response.data.status == "401") {
      let sdsdddda = localStorage.getItem("sdsdddda");

      localStorage.setItem("cc", "");
      localStorage.setItem("son", "");
      localStorage.setItem("name", "");
      localStorage.setItem("haye", "");
      localStorage.setItem("vip", "");
      localStorage.setItem("toos", "");
      localStorage.setItem("user_id", "");
      localStorage.setItem("ret_url", "");

      router
        .push({
          path: "/login", //可以改成name
        })
        .then(() => {
          window.location.reload(); // 这将导致整个页面重新加载
        });
    }
    if (response.data.status == "51314") {
      ElMessage({
        message: response.data.message,
        type: "error",
      });
    }
    if (response.data.status == "9527") {
      ElMessageBox({
        title: "",
        message: `
    <div style="display: flex;  align-items: center;width: 390px; ">
    <div  style="display: flex;  align-items: center;width: 390px; ">
      <img src="${asdasddas}" alt="" style="width: 190px;">
    </div>
    
           
    <div style=' '>
      <p style='font-size: 20px; font-weight: 700; margin-bottom: 10px;'>试用激活</p>
            <p style='font-size: 20px; font-weight: 700; color:blue;margin-top: 10px'>请扫码，联系客服</p>
          
             
            <img src="${numd}" alt="" style="width: 100px;margin-left: 60px; position: relative;">
          
    </div>
            
           
          </div>
    `,
        dangerouslyUseHTMLString: true,
        confirmButtonText: "立即充值&激活码激活",

        showClose: false,
        closeOnClickModal: false,
        center: true,
        customClass: "rounded-message-box",
      })
        .then(() => {
          localStorage.setItem("asdaaad", 4);
        })
        .catch(() => {
          localStorage.setItem("asdaaad", 4);
        });
    }
    if (response.data.status == "9528") {
      ElMessageBox({
        title: "提示",
        message: `
      <div style="display: flex; flex-direction: column; align-items: center;width: 390px;">
        <p style='font-size: 20px; font-weight: 700; color:blue'>您的账户异常，请联系小助理!</p>
        <img src="${erwm}" alt="" style="width: 150px;">
      </div>
    `,
        dangerouslyUseHTMLString: true,
        showClose: false, // 隐藏右上角的关闭按钮
        showCancelButton: false, // 隐藏取消按钮
        showConfirmButton: false, // 隐藏确认按钮
        closeOnClickModal: false, // 禁用点击遮罩层关闭
      });
    }
    if (response.data.status == "252352") {
      ElMessage({
        message: "生成失败",
        type: "error",
      });
    }
    if (response.data.status == "415135") {
      let dasd = localStorage.getItem("grow_up");
      if (dasd == 3) {
        ElMessageBox.alert("当前网络有异常波动，请重新刷新页面", "提示", {
          // if you want to disable its autofocus
          // autofocus: false,
          confirmButtonText: "OK",
        });
      }
      if (dasd == 0) {
        ElMessageBox.alert(
          "当前体验通道用户请求较多，请稍后再试。或充值年卡套餐，将解除所有限制。",
          "提示",
          {
            // if you want to disable its autofocus
            // autofocus: false,
            confirmButtonText: "OK",
            callback: (action) => {},
          }
        );
      }
      if (dasd == 1 || dasd == 2) {
        ElMessageBox.alert(
          "当前通道用户请求较多，请稍后再试。或充值年卡套餐，将解除所有限制。",
          "提示",
          {
            // if you want to disable its autofocus
            // autofocus: false,
            confirmButtonText: "OK",
            callback: (action) => {},
          }
        );
      }
      if (dasd == 4) {
        ElMessageBox.alert(
          "当前免费通道用户请求较多，请稍后再试。或充值年卡套餐，将解除所有限制。",
          "提示",
          {
            // if you want to disable its autofocus
            // autofocus: false,
            confirmButtonText: "OK",
            callback: (action) => {},
          }
        );
      }
    }

    if (response.data.status == "403") {
      vipkoc.value = "ture";
    } else {
      vipkoc.value = "false";
    }
    if (response.data.status == "41309") {
      console.log();
    }
    if (response.data.status == "51315") {
      ElMessage({
        message: response.data.message,
        type: "error",
      });
    }
    if (response.data.status == "51316") {
      ElMessage({
        message: response.data.message,
        type: "error",
      });
    }
    if (response.data.status == "51317") {
      ElMessage({
        message: response.data.message,
        type: "error",
      });
    }

    return response.data;
  },
  (error) => {
    // 这里应该处理所有可能出现的错误
    if (error.response) {
      // 服务器返回了状态码
      if (error.response.status == "401") {
        localStorage.setItem("cc", "");
        localStorage.setItem("son", "");
        localStorage.setItem("name", "");
        localStorage.setItem("haye", "");
        localStorage.setItem("toos", "");
        localStorage.setItem("user_id", "");
        localStorage.setItem("vip", "");
        localStorage.setItem("ret_url", "");
        router
          .push({
            path: "/login", //可以改成name
            query: {},
          })
          .then(() => {
            window.location.reload(); // 这将导致整个页面重新加载
          });
        // 处理401错误，比如跳转到登录页面或者提示重新登录
        // ElMessage({
        //   message: '未授权，请重新登录',
        //   type: 'error',
        // });
        vipkoc.value = "false";
        return Promise.resolve(null); // 或者根据你的逻辑返回其他值
      } else if (error.response.status == "403") {
        vipkoc.value = "ture";
      } else {
        vipkoc.value = "false";
        // 其他错误状态码处理
        ElMessage({
          message: `您的网络波动异常，状态码：${error.response.status}`,
          type: "error",
        });
        // 返回一个空的Promise或者根据你的逻辑返回其他值
        return Promise.resolve(null); // 或者其他逻辑
      }
    } else if (error.request) {
      // 请求已发出，但没有收到响应
      // ElMessage({
      //   message: '请求没有收到响应',
      //   type: 'error',
      // });
      return Promise.resolve(null); // 或者其他逻辑
    } else {
      // 触发您的网络波动异常
      ElMessage({
        message: "您的网络波动异常",
        type: "error",
      });
      return Promise.resolve(null); // 或者其他逻辑
    }
    Promise.reject(error);
  }
);

const requeswee = axios.create({
  baseURL: spodasdken,
  timeout: 120000,
});
// 请求拦截器

requeswee.interceptors.request.use(
  (config) => {
    // config.headers['Cache-Control'] = 'no-cache';
    // 检查是否有需要发送的数据
    //  loading = ElLoading.service({
    //     lock: true,
    //     text: 'SmartSchool',
    //     background: 'rgba(0, 0, 0, 0.7)',
    //   })
    if (config.data) {
      // config.headers['Cache-Control'] = 'max-age=25920000';
      // 创建FormData对象
      const formData = new FormData();
      // 遍历对象属性，添加到FormData中
      for (const key in config.data) {
        if (config.data.hasOwnProperty(key)) {
          formData.append(key, config.data[key]);
        }
      }
      // 更新config.data为转换后的FormData
      config.data = formData;
      // 设置请求头为表单格式
      config.headers["Content-Type"] = "multipart/form-data";
    }

    let token = localStorage.getItem("cc");

    if (token && token != "undefined") {
      // 设置请求的头信息
      config.headers["Authorization"] = "Bearer " + token; // 请求添加的token（此处也可不写）
    }
    // 请求添加的token（此处也可不写）
    //  config.headers['Authorization'] ="Bearer "+"eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzExNDE2NzYxLCJpYXQiOjE3MTEzMzAzNjEsImp0aSI6ImIxMzAyYmFmNzUxNjQyZDM5YzNiZjBhYzRkOTgwOWM3IiwidXNlcl9pZCI6MjN9.FrRthNIUEXSarMMhdp78Fgh4VVeVKsy4_W9p8ci6-AE.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzExMTcxODEyLCJpYXQiOjE3MTEwODU0MTIsImp0aSI6IjA3NGVlZThkNGE3ODQ3ZGM4NWIwNmIwNWJjOWQ2ZDJmIiwidXNlcl9pZCI6MjN9.MF44XCoi-eznFAPBy2G7fzm98uUnRJ0fHqmEXeryaZQ"; // 请求添加的token（此处也可不写）

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
); // 添加响应拦截器
requeswee.interceptors.response.use(
  (response) => {
    // 对响应数据做些什么

    if (
      response.data.status != "200" &&
      response.data.status != "401" &&
      response.data.status != "51314" &&
      response.data.status != "41309" &&
      response.data.status != "51316" &&
      response.data.status != "51315" &&
      response.data.status != "51317" &&
      response.data.status != "403" &&
      response.data.status != "4321512" &&
      response.data.status != "415135" &&
      response.data.status != "252352" &&
      response.data.status != "9527" &&
      response.data.status != "9528"
    ) {
      ElMessage({
        message: response.data.message,
        type: "error",
      });
    }
    if (response.data.status == "401") {
      let sdsdddda = localStorage.getItem("sdsdddda");

      localStorage.setItem("cc", "");
      localStorage.setItem("son", "");
      localStorage.setItem("name", "");
      localStorage.setItem("haye", "");
      localStorage.setItem("vip", "");
      localStorage.setItem("toos", "");
      localStorage.setItem("user_id", "");
      localStorage.setItem("ret_url", "");

      router
        .push({
          path: "/login", //可以改成name
        })
        .then(() => {
          window.location.reload(); // 这将导致整个页面重新加载
        });
    }
    if (response.data.status == "51314") {
      ElMessage({
        message: response.data.message,
        type: "error",
      });
    }
    if (response.data.status == "9527") {
      ElMessageBox({
        title: "",
        message: `
    <div style="display: flex;  align-items: center;width: 390px; ">
    <div  style="display: flex;  align-items: center;width: 390px; ">
      <img src="${asdasddas}" alt="" style="width: 190px;">
    </div>
    
           
    <div style=' '>
      <p style='font-size: 20px; font-weight: 700; margin-bottom: 10px;'>试用激活</p>
            <p style='font-size: 20px; font-weight: 700; color:blue;margin-top: 10px'>请扫码，联系客服</p>
          
             
            <img src="${numd}" alt="" style="width: 100px;margin-left: 60px; position: relative;">
          
    </div>
            
           
          </div>
    `,
        dangerouslyUseHTMLString: true,
        confirmButtonText: "立即充值&激活码激活",

        showClose: false,
        closeOnClickModal: false,
        center: true,
        customClass: "rounded-message-box",
      })
        .then(() => {
          localStorage.setItem("asdaaad", 4);
        })
        .catch(() => {
          localStorage.setItem("asdaaad", 4);
        });
    }
    if (response.data.status == "9528") {
      ElMessageBox({
        title: "提示",
        message: `
      <div style="display: flex; flex-direction: column; align-items: center;width: 390px;">
        <p style='font-size: 20px; font-weight: 700; color:blue'>您的账户异常，请联系小助理!</p>
        <img src="${erwm}" alt="" style="width: 150px;">
      </div>
    `,
        dangerouslyUseHTMLString: true,
        showClose: false, // 隐藏右上角的关闭按钮
        showCancelButton: false, // 隐藏取消按钮
        showConfirmButton: false, // 隐藏确认按钮
        closeOnClickModal: false, // 禁用点击遮罩层关闭
      });
    }
    if (response.data.status == "252352") {
      ElMessage({
        message: "生成失败",
        type: "error",
      });
    }
    if (response.data.status == "415135") {
      let dasd = localStorage.getItem("grow_up");
      if (dasd == 3) {
        ElMessageBox.alert("当前网络有异常波动，请重新刷新页面。", "提示", {
          // if you want to disable its autofocus
          // autofocus: false,
          confirmButtonText: "OK",
        });
      }
      if (dasd == 0) {
        ElMessageBox.alert(
          "当前体验通道用户请求较多，请稍后再试。或充值年卡套餐，将解除所有限制。",
          "提示",
          {
            // if you want to disable its autofocus
            // autofocus: false,
            confirmButtonText: "OK",
            callback: (action) => {},
          }
        );
      }
      if (dasd == 1 || dasd == 2) {
        ElMessageBox.alert(
          "当前通道用户请求较多，请稍后再试。或充值年卡套餐，将解除所有限制。",
          "提示",
          {
            // if you want to disable its autofocus
            // autofocus: false,
            confirmButtonText: "OK",
            callback: (action) => {},
          }
        );
      }
      if (dasd == 4) {
        ElMessageBox.alert(
          "当前免费通道用户请求较多，请稍后再试。或充值年卡套餐，将解除所有限制。",
          "提示",
          {
            // if you want to disable its autofocus
            // autofocus: false,
            confirmButtonText: "OK",
            callback: (action) => {},
          }
        );
      }
    }

    if (response.data.status == "403") {
      vipkoc.value = "ture";
    } else {
      vipkoc.value = "false";
    }
    if (response.data.status == "41309") {
      console.log();
    }
    if (response.data.status == "51315") {
      ElMessage({
        message: response.data.message,
        type: "error",
      });
    }
    if (response.data.status == "51316") {
      ElMessage({
        message: response.data.message,
        type: "error",
      });
    }
    if (response.data.status == "51317") {
      ElMessage({
        message: response.data.message,
        type: "error",
      });
    }

    return response.data;
  },
  (error) => {
    // 这里应该处理所有可能出现的错误
    if (error.response) {
      // 服务器返回了状态码
      if (error.response.status == "401") {
        localStorage.setItem("cc", "");
        localStorage.setItem("son", "");
        localStorage.setItem("name", "");
        localStorage.setItem("haye", "");
        localStorage.setItem("toos", "");
        localStorage.setItem("user_id", "");
        localStorage.setItem("vip", "");
        localStorage.setItem("ret_url", "");
        router
          .push({
            path: "/login", //可以改成name
            query: {},
          })
          .then(() => {
            window.location.reload(); // 这将导致整个页面重新加载
          });
        // 处理401错误，比如跳转到登录页面或者提示重新登录
        // ElMessage({
        //   message: '未授权，请重新登录',
        //   type: 'error',
        // });
        vipkoc.value = "false";
        return Promise.resolve(null); // 或者根据你的逻辑返回其他值
      } else if (error.response.status == "403") {
        vipkoc.value = "ture";
      } else {
        vipkoc.value = "false";
        // 其他错误状态码处理
        ElMessage({
          message: `您的网络波动异常，状态码：${error.response.status}`,
          type: "error",
        });
        // 返回一个空的Promise或者根据你的逻辑返回其他值
        return Promise.resolve(null); // 或者其他逻辑
      }
    } else if (error.request) {
      // 请求已发出，但没有收到响应
      // ElMessage({
      //   message: '请求没有收到响应',
      //   type: 'error',
      // });
      return Promise.resolve(null); // 或者其他逻辑
    } else {
      // 触发您的网络波动异常
      ElMessage({
        message: "您的网络波动异常",
        type: "error",
      });
      return Promise.resolve(null); // 或者其他逻辑
    }
    Promise.reject(error);
  }
);
export default request;
export { api, tools, spoken, vipkoc, wws, requeswee, word, python,multiPersonSynthesis};
